
























import { Vue, Component } from "vue-property-decorator";
import '@/App.container';
import pgaDiContainer from '@/App.container';
import IOrderService from '@/services/iOrderService'
import OrderService from '@/services/OrderService';
import IGraphService from '@/services/iGraphService'
import GraphService from '@/services/GraphService';

@Component({
    //data: () => ({

    //}),
})
export default class About extends Vue {
    e1 = 1;
    testResponse = {};
    meets = [];

    private listTechnologies = 
    [
        { text: 'Docker', icon: 'mdi-docker' },
        { text: 'Kubernetes', icon: 'mdi-kubernetes' },
        { text: 'ASP.NET Core', icon: 'mdi-microsoft' },
        { text: 'C#', icon: 'mdi-microsoft-visual-studio-code' },
        { text: 'CosmosDB', icon: 'mdi-microsoft-azure' },
        { text: 'SQL Server', icon: 'mdi-microsoft-access' },
        { text: 'Vue', icon: 'mdi-vuejs' },
        { text: 'Vuetify', icon: 'mdi-vuetify' },
    ];

    pokemonService: IOrderService = pgaDiContainer.get<IOrderService>(OrderService);
    graphService: IGraphService = pgaDiContainer.get<IGraphService>(GraphService);
}
